/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-right .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #1f1f1f;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #303030;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: 0;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(255, 255, 255, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #303030;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter,
.ant-drawer-panel-motion-left-appear {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter,
.ant-drawer-panel-motion-right-appear {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter,
.ant-drawer-panel-motion-top-appear {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter,
.ant-drawer-panel-motion-bottom-appear {
  transform: translateY(100%);
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-drawer .ant-picker-clear,
.ant-drawer .ant-slider-handle,
.ant-drawer .ant-anchor-wrapper,
.ant-drawer .ant-collapse-content,
.ant-drawer .ant-timeline-item-head,
.ant-drawer .ant-card {
  background-color: #1f1f1f;
}
.ant-drawer .ant-transfer-list-header {
  background: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.ant-drawer tr.ant-table-expanded-row > td,
.ant-drawer tr.ant-table-expanded-row:hover > td {
  background: #272727;
}
.ant-drawer .ant-table.ant-table-small thead > tr > th {
  background-color: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table {
  background-color: #1f1f1f;
}
.ant-drawer .ant-table .ant-table-row-expand-icon {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tfoot > tr > th,
.ant-drawer .ant-table tfoot > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table thead > tr > th {
  background-color: #272727;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tbody > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-left,
.ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-right {
  background-color: #1f1f1f;
}
.ant-drawer .ant-table tbody > tr.ant-table-row:hover > td {
  background: #303030;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered thead > tr > th,
.ant-drawer .ant-table.ant-table-bordered tbody > tr > td,
.ant-drawer .ant-table.ant-table-bordered tfoot > tr > th,
.ant-drawer .ant-table.ant-table-bordered tfoot > tr > td {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #303030;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-container {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table .ant-table-filter-trigger-container-open {
  background-color: #525252;
}
.ant-drawer .ant-picker-calendar-full {
  background-color: #1f1f1f;
}
.ant-drawer .ant-picker-calendar-full .ant-picker-panel {
  background-color: #1f1f1f;
}
.ant-drawer .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #3a3a3a;
}
.ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}
.ant-drawer .ant-badge-count {
  box-shadow: 0 0 0 1px #1f1f1f;
}
.ant-drawer .ant-tree-show-line .ant-tree-switcher {
  background: #1f1f1f;
}
